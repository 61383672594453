<template>
  <div class="userinfo-orderList">
    <div class="title pointer flex-center-start">
      <i class="el-icon-arrow-left" @click="back"></i>
      {{ $t('shang-pin-shou-cang-0') }}
    </div>

    <div class="collcet-list">
      <goodsList :list="goodsList" class="line-4" :hideCount="true"></goodsList>
    </div>

    <div class="flex-center user-pager">
      <el-pagination
        background
        @current-change="currentChange"
        :current-page="page.current"
        :page-size="page.size"
        layout=" prev, pager,  next"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getToken, getUserId } from '@/utils/auth'
import { shopProductList } from '@/api/shop'
import goodsList from '@/components/goodsList'
export default {
  components: {
    goodsList
  },
  data() {
    return {
      goodsList: [],

      emptyUrl: require('@/assets/imgs/emtpy.svg'),
      page: {
        current: 1,
        size: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      shopProductList({
        current: this.page.current,
        pageSize: this.page.size,
        UId: parseFloat(getUserId())
      }).then((res) => {
        this.goodsList = res.data.Items
      })
    },
    changeTab(i) {
      this.tabIndex = i
    },
    back() {
      this.$router.go(-1)
    },
    currentChange(page) {
      this.page.current = page
      this.init()
    }
  }
}
</script>